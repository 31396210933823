body {
  color: #4a4f54;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.py-11{
  padding-top:5rem;
  padding-bottom:5rem;
}


.bg-custom{
  background:rgba(185,221,98,0.5);
}

.bg-custom-light{
  background:rgba(185,221,98,0.4);
}

.lead-light{
  font-weight: 300;
}


.text-right{
  text-align: right;
}

.color-purple{
  color:#a51b7b;
}